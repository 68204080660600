.section-main{
   position: relative;
   overflow: hidden;
}

.section-main::after{
    position: absolute;
    height: 100%;
    width: 20%;
    right: 0;
    top: 0;
    content: '';
    background-color: var(--color-primary);
}

/* -- MAIN SECTION LEFT SIDE -- */
.home-content{
    max-width: 35rem;
    padding: 10.3rem 0;
}

.home-content h2{
    font-weight: 700;
    line-height: 1;
    color: var(--color-white);
    margin-bottom: 20px;
}

.home-content h2 span{
    color: var(--color-primary);
}

.home-content h1{
    font-weight: 700;
    line-height: 1;
    color: var(--color-white);
    margin-bottom: 25px;
}

.home-content p{
    color: var(--color-white);
}

/* -- MAIN SECTION RIGHT SIDE -- */
.profession-container{
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 630px;
    height: 630px;
    overflow: hidden;
    pointer-events: none;
}

.profession-container .profession-box{
    position: relative;
    top: 0;
    right: -35px;
    width: 630px;
    height: 630px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: professionRotate 13s ease-out infinite;
}

.profession-container .profession-box .profession{
    position: absolute;
    z-index: 2;
    left: 0;
    text-align: center;
    color: var(--color-primary);
    transform: rotate(calc(360deg / 4 * var(--i)));
    transform-origin: 300px;
    background: var(--color-bg-1);
    padding: 5px 0;
}

.profession-container .profession-box .profession:nth-child(1){
    margin-top: -15px;
    margin-left: -15px;
}

.profession-container .profession-box .profession:nth-child(2){
    margin-left: 20px;
}

.profession-container .profession-box .profession:nth-child(3){
    margin-left: 55px;
    margin-top: 20px;
}

.profession-container .profession-box .profession:nth-child(4){
    margin-left: 10px;
    margin-top: 85px;
}

.profession-container .profession-box .profession svg{
    font-size: 2rem;
}

.profession-container .profession-box .profession h3{
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 600;
}

.profession-container .profession-box .circle{
    width: 470px;
    height: 470px;
    border: 3px solid var(--color-primary);
    border-radius: 50%;
    position: absolute;
}

.profession-container .overlay{
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 0;
    border-top: 315px solid var(--color-primary);
    border-bottom: 315px solid var(--color-primary);
    border-right: 315px solid var(--color-primary);
    border-left: 315px solid transparent;
}

.home-img{
    position: absolute;
    bottom: -7px;
    right: 70px;
}

.home-img img{
    width: 340px;
}

@keyframes professionRotate{
    0%, 20%{
        transform: rotate(0deg);
    }
    25%, 45%{
        transform: rotate(-90deg);
    }
    50%, 70%{
        transform: rotate(-180deg);
    }
    75%, 95%{
        transform: rotate(-270deg);
    }
    100%{
        transform: rotate(-360deg);
    }
}


/* ============== CTA ==============  */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: start;
}

/* ============== HEADER SOCIALS ==============  */
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 0;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ============== SCROLL DOWN ==============  */
.scroll__down{
    position: absolute;
    right: 3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    color: #FFFFFF;
    z-index: 3;
}

.scroll__down svg{
    transform: rotate(270deg);
    font-size: 1.2rem;
    position: relative;
    top: 5.4px;
}

/* ============== MEDIA QUERIES  =============  */
@media screen and (min-width: 2560px){
    .section-main::after {
        display: none;
    }
}

@media (min-width: 1440px) and (max-width: 2559px){
    .section-main::after {
        width: 30%;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .home-content {
        max-width: 27rem;
        padding: 7rem 0;
    }

    .profession-container {
        width: 540px;
    }

    .profession-container .overlay{
        border-top: 315px solid var(--color-primary);
        border-bottom: 290px solid var(--color-primary);
        border-right: 250px solid var(--color-primary);
        border-left: 150px solid transparent;
    }

    .home-img {
        position: absolute;
        bottom: 0px;
        right: 40px;
    }

    .home-img img {
        width: 300px;
    }

    .scroll__down {
        right: 0;
    }

    .header__socials{
        gap: 0;
    }
}

@media screen and (max-width: 1199px){
    
}

@media screen and (max-width: 991px){
    .section-main::after{
        width: 6%;
    }

    .home-content {
        max-width: 100%;
        padding: 3.5rem 0;
    }

    .home-content p{
        font-size: 14px;
        width: 70%;
    }

    .profession-container .overlay {
        border-top: 332px solid var(--color-primary);
        border-bottom: 211px solid var(--color-primary);
        border-right: 36px solid var(--color-primary);
        border-left: 87px solid transparent;
    }

    .profession-container {
        width: 300px;
        height: 465px;
    }

    .home-content .cta{
        display: inline-block;
    }

    .home-content .cta .btn{
        display: block;
    }

    .home-content .cta .btn:nth-child(2){
        margin-top: 15px;
    }

    .home-img {
        display: none;
    }

    .scroll__down {
        display: none;
    }

    .header__socials{
        display: none;
    }
}

@media screen and (max-width: 767px){
    .home-content p {
        width: 60%;
    }

    .profession-container{
        height: 485px;
    }
}

@media screen and (max-width: 575px){
    .section-main::after {
        width: 4%;
    }

    .home-content h1{
        font-size: 2rem;
    }

    .home-content p {
        font-size: 13px;
    }

    .home-content .cta .btn {
        font-size: 13px;
    }

    .profession-container .profession-box .profession svg,
    .profession-container .profession-box .profession h3
    {
        font-size: 1rem;
    }

    .profession-container .profession-box {
        top: 57px;
    }
    
    .profession-container {
        height: 100%;
        width: 205px;
    }

    .profession-container .overlay {
        border-top: 370px solid var(--color-primary);
        border-bottom: 250px solid var(--color-primary);
        border-right: 7px solid var(--color-primary);
        border-left: 45px solid transparent;
    }

    .profession-container .profession-box .profession:nth-child(1) {
        margin-left: 20px;
    }

    .profession-container .profession-box .profession:nth-child(2) {
        margin-top: 41px;
    }

    .profession-container .profession-box .profession:nth-child(3) {
        margin-left: 18px;
    }

    .profession-container .profession-box .profession:nth-child(4) {
        margin-top: 15px;
    }
}