@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/* :root{
    --color-bg-1: #001e3c;
    --color-bg-2: #0a1929;
    --color-bg-3: #143352;
    --color-bg-varient: #132f4c;
    --color-primary: #66b2ff;
    --color-primary-varient: #265d97;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-light: rgba(255, 255, 255, 0.6);
    --shadow-color: rgba(0, 0, 0, .2);

    --transition: all 400ms ease;
} */

/* Dark Mode */
.dark-mode{
    --color-bg-1: #001e3c;
    --color-bg-2: #0a1929;
    --color-bg-3: #143352;
    --color-bg-varient: #132f4c;
    --color-primary: #66b2ff;
    --color-primary-varient: #265d97;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-light: rgba(255, 255, 255, 0.6);
    --shadow-color: rgba(0, 0, 0, .2);

    --transition: all 400ms ease;
}

/* Light Mode */
.light-mode {
    --color-bg-1: #FFFFFF;
    --color-bg-2: #F1F1F1;
    --color-bg-3: #EDEDED;
    --color-bg-varient: #E5E5E5;
    --color-primary: #0455a7;
    --color-primary-varient: #0e4580;
    --color-white: #000000;
    --color-black: #000000;
    --color-light: rgba(0, 0, 0, 0.6);
    --shadow-color: rgba(0, 0, 0, 0.2);

    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
}

/* ============ GENERAL STYLES ===========  */
h1, h2, h3, h4, h5, h6, p{
    margin-bottom: 0;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

p{
    font-size: 1rem;
}

.section-p-tb{
    padding: 4.5rem 0;
}

.section-title{
    margin-bottom: 2rem;
    text-align: center;
}

.section-title h4{
    color: var(--color-light);
}

.section-title h2{
    color: var(--color-white);
}

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    box-shadow: 0 .2rem .5rem var(--shadow-color);
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg-1);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg-1);
}

.img-fluid{
    max-width: 100%;
    height: auto;
}

.bg-color-1{
    background-color: var(--color-bg-1);
}

.bg-color-2{
    background-color: var(--color-bg-2);
}

.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.position-relative{
    position: relative;
}

.position-absolute{
    position: absolute;
}

/* color mode switch */
.color-mode-switch{
    position: fixed !important;
    z-index: 5;
    right: 4.6rem;
    top: 1rem;
}

.color-mode-switch .react-switch-bg{
    text-align: center;
    background: rgb(21, 35, 61) !important;
}

.color-mode-switch .react-switch-bg svg{
    color: #FFFFFF;
    font-size: 1.4rem;
    margin-top: 3px;
}

.container{
    width: 100%;
    padding-right: calc(1.5rem * 0.5);
    padding-left: calc(1.5rem * 0.5);
    margin-right: auto;
    margin-left: auto;
}

/* ============== MEDIA QUERIES =============  */
@media screen and (min-width: 1200px){
    .container {
        max-width: 1240px;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .container {
        max-width: 1150px;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .container {
        max-width: 910px;
    }
}

@media (min-width: 576px) and (max-width: 767px){
    .container {
        max-width: 730px;
    }

    .color-mode-switch{
        right: 1.6rem;
    }
}

@media (max-width: 575px){
    .color-mode-switch{
        right: 0.6rem;
    }
}